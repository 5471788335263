<template>
    <SituacaoRe />
</template>

<script>
    import SituacaoRe from '../../components/cadastro/SituacaoReList.vue';

    export default {
        name: 'SituacaoRe Page',
        components: { SituacaoRe }
    }

</script>